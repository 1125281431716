<template>
  <v-card light elevation="0" class="text-decoration-underline pa-2">
    <v-img width="72" :src="require('@/assets/logo.png')"></v-img>
  </v-card>
</template>

<script>
export default {
  name: "LogoDisplay"
}
</script>

<style scoped>

</style>
