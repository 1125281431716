<template>
  <div class="font-weight-bold text-body-1 text-decoration-underline my-6 mt-8">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "InfoLineSubheader"
}
</script>

<style scoped>

</style>
