import { render, staticRenderFns } from "./PageTitle.vue?vue&type=template&id=b0e9f72c&scoped=true&"
import script from "./PageTitle.vue?vue&type=script&lang=js&"
export * from "./PageTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0e9f72c",
  null
  
)

export default component.exports